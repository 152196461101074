import React from 'react';

function User({ path }) {

    return (
        <div style={{color: 'green'}}>
            root@DESKTOP-SANS {path}
        </div>
    )

}

export default User;