import React from 'react';

function Directory() {
    return(
        <p>
            <span style={{paddingRight: 10}}>readme.txt</span> solution.js
        </p>
    )
}

export default Directory;